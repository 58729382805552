
body{
  scroll-behavior: smooth;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
  
    font-size: 18px;
  
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background-color: #000;
  }
 

  .hero{
    background-image: url('../public/assets/hero.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    

  }
  

  .hero2{
    background-image: url('../public/assets/hero2.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
  }

  .hero3{
    background-image: url('../public/assets/hero3.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
  }

  .italic{
    font-style: italic;
  }

  .map{
    background-image: url('../public/assets/map.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
  }

  .dropdown {
    position: relative;
    display: inline-block;
    
  }
  
  .dropdown-button {
    background: rgb(1, 29, 84); /* Use your desired color */
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
  }
  
  .dropdown-button img {
    border-radius: 50%;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    left: -40px;
    background-color: rgb(1, 29, 84);
    min-width: 160px;
    color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* This will be applied when the dropdown is open */
  .dropdown .dropdown-content {
    display: block;
    background-color: black;
  }
  /* Add this to your CSS file */
  .transition-width {
    transition: width 300ms ease-in-out;
  }
  
  /* This will hide the sidebar content when the width is 0 */
  .w-0 {
    width: 0;
    overflow: hidden;
  }
  /* App.css or wherever your styles are defined */
  .bg-gray-700 {
    background-color: #4a5568; /* This is the Tailwind CSS gray-700 color */
  }
  
  /* Add this to your CSS file */
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .fade-in-right {
    animation: fadeInRight 1s ease forwards;
  }
  /* BalanceSummaryCard.css */
  .balance-summary-card {
    background: transparent;
    border-radius: 20px;
    color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 560px; /* Default width for larger screens */
    margin: auto; /* Center align the card if needed */
}

/* Adjust styles for tablets */
@media (max-width: 768px) {
    .balance-summary-card {
        width: 90%; /* Make width responsive to the screen size */
        padding: 15px; /* Slightly reduce padding */
    }
}

/* Adjust styles for mobile phones */
@media (max-width: 480px) {
    .balance-summary-card {
        width: 94%; /* Increase width to take up more of the small screen */
        padding: 20px; /* Further reduce padding for small screens */
        font-size: 0.9em; /* Optionally adjust font size */
        height: 100%; /* Optionally adjust height */
    }
}

  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .balance-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  
  .bg{
    background-color: rgb(1, 29, 84)
    
  }
  /* Add more styles for toggle buttons and other elements */
  .recent{
   
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .card{
  
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .chart{
  
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .progress{
   
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .footer{
    
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .font{
    font-style: italic;
  }
 
  .line {
    display: block; /* Display block to take up its own space */
    height: 2px; /* The thickness of your line */
    width: 70px; /* The width of your line */
    background-color: #F44E77; /* The pink color for your line */
    margin: 0 auto; /* Center the line horizontally */
  }
  .nocopy {
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  }
  .responsive-image {
    width: 100%;
    max-width: 550px;
    height: auto;
  }
  
  @media (max-width: 600px) {
    .responsive-image {
      width: 90%;
    }
  }
  /* ClockStyle.css */
.react-clock__face {
  background-color: white;
  border: 2px solid #2d3748; /* Gray-800 from Tailwind */
  border-radius: 50%;
}

.react-clock__hand__body {
  background-color: #2d3748; /* Gray-800 from Tailwind */
}

.react-clock__mark__body {
  background-color: #cbd5e0; /* Gray-400 from Tailwind */
}

.react-clock__mark__number {
  color: #2d3748; /* Gray-800 from Tailwind */
}
@keyframes flip {
  0% {
    transform: rotateX(0);
  }
  50% {
    transform: rotateX(-90deg);
    background-color: white;
    color: transparent;
  }
  100% {
    transform: rotateX(0);
  }
}

.animate-flip {
  display: inline-block;
  perspective: 1000px;
}

.minutes, .seconds {
  transition: transform 0.6s, background-color 0.3s linear, color 0.3s linear;
  transform-origin: bottom center;
  animation: flip 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955) 1;
}

.minutes {
  animation-delay: 59s; /* approx delay for changing minutes */
}

.seconds {
  animation-delay: 1s; /* approx delay for changing seconds */
}
.bars-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Take up full height */
  width: 100vw; /* Take up full width */
}

.bar {
  display: inline-block;
  width: 4px;
  height: 24px;
  border-radius: 4px;
  animation: scale 1s infinite ease-in-out;
  background-color: #333; /* Change to match your color scheme */
  margin: 0 2px;
}

.bar:nth-child(1) {
  animation-delay: 0s;
}

.bar:nth-child(2) {
  animation-delay: 0.2s;
}

.bar:nth-child(3) {
  animation-delay: 0.4s;
}

.bar:nth-child(4) {
  animation-delay: 0.6s;
}

.bar:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes scale {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
  }
}
